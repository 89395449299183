import { InputAdornment, TextField } from "@mui/material";
import { useContext } from "react";
import Icon from "react-crypto-icons";
import { AppContext } from "../../context/AppContext";
import icons from '../../icons.json'

const buttonStyle =
{
  "height": "42px",
  "lineHeight": "42px",
  "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
  "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
  "borderRadius": "44px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "14px",
  "textAlign": "center",
  "textTransform": "uppercase",
  "color": "#FFFFFF"
}
const BidButton = ({
  assetId,
  defaultValue,
  value,
  onChange,
  onClick,
  unitname,
  iconName,
}) => {
  const { providerEnv } = useContext(AppContext)
  console.log({providerEnv})
  return <>
    <TextField
      name="BID"
      id="input-with-icon-textfield"
      type="number"
      size="small"
      variant="standard"
      label="Bid amount"
      fullWidth={true}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {assetId
              ? <>
                <img style={{ height: '20px' }} src={assetId in icons[providerEnv] ? icons[providerEnv][assetId] : icons[providerEnv]["icon-placeholder"]} />&nbsp;<span>{unitname}</span>
              </>
              : unitname
                ? <span>{unitname}</span>
                : <Icon name={iconName || "algo"} size={16} />}
          </InputAdornment>
        ),
        defaultValue,
        value,
      }}
      onChange={onChange}
    />
    <div role="button"
      style={{
        ...buttonStyle,
        "marginTop": "15px"
      }}
      onClick={onClick}
    >
      Make A Bid
    </div>
  </>
}
export default BidButton;