import { FeatureFlagContext } from "../context/FeatureFlagContext";
import React, { useEffect, useState } from "react"
import moment from 'moment';
import {
  useParams,
  useHistory
} from "react-router-dom";
import {
  getAccountInfo,
  getAppInfo,
  getAccountTransaction,
  getBlock,
  getAccInfo,
  formatCompactAddress,
  getExplorer,
  getAssetImage,
  fetchAssetImage,
  getAssetImageFromUrl,
  getAsset,
  placeholderImage,
} from '../functions'
import { useSnackbar } from 'notistack';
import AuctionLoader from '../loaders/AuctionLoader';
import ContractLoader from "../loaders/ContractLoader";
import AuctionView from '../views/AuctionView'
import Card from '../components/Card'
import { Button, Col, Image, Row, Spinner, Table } from "react-bootstrap";
import GalleryCard from "../components/GalleryCard";
import styles from './auction.module.css'
import md5 from "blueimp-md5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import appService from "../service/appService";
import offerService from "../service/offerService";
import { Badge } from "@mui/material";
import BasicModal from "../components/BasicModal";
import Icon from 'react-crypto-icons'
import { default as collections } from '../hooks/useCollection'
import PlaceholderCard from "../components/PlaceholderCard";

const Asset = (props) => {
  const history = useHistory()
  const WAValidator = require('@swyftx/api-crypto-address-validator')
  document.title = "Collection - NFT Jam"
  const {
    stdlib,
    acc,
    approvalProgram,
    firstBlock,
    ADDR_PLATFORM,
    ADDR_DISCOVERY,
  } = props
  console.log({ collections })
  let { index } = useParams()
  console.log(index)
  const isAssetId = !isNaN(parseInt(index)) && String(index).replace(/[a-z]/,'') === String(index) && index >= 0
  if(!isAssetId) history.push('/')
  const addr = "asdf"
  const isCollection = addr in collections
  const isAlgoAddress = addr
    && WAValidator.validate(addr, 'algo')
  const isAppId = false
  const [apps, setApps] = useState(isAppId ? [addr] : [])
  const [offers, setOffers] = useState([])
  const [asset, setAsset] = useState({})
  const [offerMap, setOfferMap] = useState({})
  const [collection, setCollection] = useState(null)
  const [showOfferModal, setShowOfferModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    ctc: false,
    view: null,
    navigation: Boolean(addr) && !isNaN(parseInt(addr)) && !String(addr).match(/[^0-9]/),
    caughtUp: true,
    joined: true,
    once: true,
    bids: []
  })
  const explorer = getExplorer()

  console.log({ addr, isAlgoAddress, isAppId })
  // EFFECT
  useEffect(() => {
    (async () => {
      let assetInfo = (await getAsset(index))?.data
      let image = (await getAssetImageFromUrl(assetInfo?.url))
      setAsset({...assetInfo, image})
    })()
  }, [])
  const getOffers = assetId => {
    if (!(assetId in offerMap)) return 0
    return offerMap[assetId].length
  }

  /*
   * handleKeyPress
   * - handles input from auction search
   */
  const handleKeyPress = async (event) => {
    let { key, target } = event
    let { value } = target
    const isAlgoAddress =
      WAValidator.validate(value, 'algo')
    const isAppId = !isAlgoAddress
      && /^[0-9]+$/.test(value)
    if (isAlgoAddress) {
      let addr = value
      let ids = (await Promise.all([
        (async addr => {
          let tx = ((await getAccountTransaction(addr))?.data?.transactions ?? []).pop()
          let round = (tx ?? {})['confirmed-round'] ?? -1
          let sender = (tx ?? {})['sender'] ?? ""
          let res = ((((await getBlock(round))?.data?.transactions ?? []).filter(el => el['tx-type'] === 'appl' && el['sender'] === sender)[0] ?? {})['application-transaction'] ?? {})['application-id'] ?? -1
          return res > 0 ? [res] : []
        }),
        (async addr => {
          let accInfo = await getAccountInfo(addr)
          let apps = accInfo['created-apps']
            .filter(app => approvalProgram === md5(app?.params['approval-program']))
            .map(({ id }) => id)
          return apps
        })
      ].map(el => el(addr)))).flatMap(el => el)
      if (ids.length > 0) {
        history.push(`/auction/${addr}`)
        setApps(null)
        setApps(ids)
      }
    }
    else if (isAppId) {
      let addr = value
      //let candidatePath = `/auction/${addr}`
      if (key === 'Enter') { //&& location.pathname !== candidatePath) {
        try {
          await getAppInfo(addr)
          history.push(`/auction/${addr}`)
          setApps(null)
          setApps([addr])
        } catch (e) {
          alert(`Auction ${addr} not found`)
        }
      }
    }
  }

  const searchBarStyle = {
    /*"width": "540px",*/
    "height": "60px",
    "background": "#FFFFFF",
    "border": "1px solid #C9C9C9",
    "boxSizing": "border-box",
    "boxShadow": "0px 8px 28px rgba(0, 0, 0, 0.10)",
    "borderRadius": "52px",
    "display": "flex"
  }
  const searchCardStyle =
  {
    "height": "277px",
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "margin": "auto"
  }
  const compactAuctionSearchCardStyle =
  {
    ...searchCardStyle,
    "width": "90vw",
    "padding": "50px 30px",
  }
  const auctionSearchCardStyle =
  {
    ...searchCardStyle,
    "width": "640px",
    "padding": "50px",
  }
  const auctionTitleStyle =
  {
    "height": "38px",
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "900",
    "fontSize": "32px",
    "lineHeight": "38px",
    "textAlign": "center",
    "letterSpacing": "0.1em",
    "textTransform": "uppercase",
    "color": "#2A3035",
    "opacity": "0.8",
  }
  const auctionDescriptionStyle = {
    "display": "none"
  }
  const auctionSearchContainerStyle =
  {
    "marginTop": "79px",
  }
  const auctionSearchbarInputStyle =
  {
    "width": "85%",
    "border": "0",
    "marginLeft": "15px",
    "borderRadius": "52px"
  }
  const auctionSearchbarInputTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "bold",
    "fontSize": "14px",
    "lineHeight": "17px",
    "letterSpacing": "0.05em",
    "color": "#484959"
  }
  const auctionStyle =
  {
    //"margin": "auto",
    "paddingBottom": "100px"
  }
  const navbarBrandTypographyStyle =
  {
    "color": "black",
    "paddingTop": "8px",
    "paddingBottom": "8px",
    "fontFamily": "Roboto",
    "fontSize": "12px",
    "fontStyle": "normal",
    "fontWeight": "600",
    "lineHeight": "14px",
    "letterSpacing": "0em",
    "textAlign": "left"
  }
  const myAuctionStyle =
  {
    "width": "100px",
    "height": "14px",
    "fontFamily": "Roboto",
    "fontStyle": "normal",
    "fontWeight": "500",
    "fontSize": "12px",
    "lineHeight": "14px",
    "color": "rgb(85, 89, 93)"
  }
  return <FeatureFlagContext.Consumer>
    {({
      myAuctions,
      auctionSearch,
    }) => isAssetId ? <div id="asset" style={auctionStyle}>
        <BasicModal open={showModal} handleOpen={() => setShowModal(true)} handleClose={() => setShowModal(false)}>
          <Row>
            <Col xs={12}>
              <LazyLoadImage src={asset?.params?.url} fluid />
            </Col>
            <Col xs={12} sm={6}>
              <span>ASSET ID:</span>
              <a style={{ float: 'right' }} href={`${explorer}/asset/${asset?.index}`} target="_blank" rel="noopener noreferrer">{asset?.index}</a>
            </Col>
          </Row>
        </BasicModal >
        <BasicModal open={showOfferModal} handleOpen={() => setShowOfferModal(true)} handleClose={() => setShowOfferModal(false)}>
          <Row style={{ width: '50vw' }}>
            <Col xs={12}>
              <Table className="w-100" borderless striped>
                <tbody>
                  {offers.map(el =>
                    <tr>
                      <td style={{ verticalAlign: "middle" }}><Icon name="algo" size="12" /> {el?.app?.currentPrice}</td>
                      <td style={{ verticalAlign: "middle" }}>{formatCompactAddress(el?.app?.highestBidder)}</td>
                      <td style={{ textAlign: "right" }}><Button onClick={() => {
                        history.push(`/offer/${el.appId}`)
                      }}>VIEW OFFER</Button></td>
                    </tr>)}
                </tbody>
              </Table>
            </Col>
          </Row>
        </BasicModal >
        {asset.image
          ? <Image src={asset.image} fluid/>
          : <Spinner animation="grow" />}
        <div className="mt-3" style={{ ...auctionTitleStyle, color: 'white' }}>{asset.assetname}</div>
      </div> : ""}
  </FeatureFlagContext.Consumer>
}

export default Asset;