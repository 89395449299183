import { Tooltip } from "@mui/material"

const AuctionIndicators = ({
  roy,
  isReservePriceMet,
  isClosed,
}) => {
  console.log({roy})
  return <>
      {roy > 0 && <Tooltip title="Royalties" placement="bottom" arrow>
        <span className="p-1">&#x1F451;</span>
      </Tooltip>}
      {isReservePriceMet ? <Tooltip title="Reserve price met" placement="bottom" arrow>
        <span className="p-1">&#128293;</span>
      </Tooltip> : !isClosed ? "Reserve not met" : ""}
  </>
}
export default AuctionIndicators