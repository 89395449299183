import { FeatureFlagContext } from "../context/FeatureFlagContext";
import React, { useEffect, useState } from "react"
import moment from 'moment';
import {
  useParams,
  useHistory
} from "react-router-dom";
import {
  getAccountInfo,
  getAppInfo,
  getAccountTransaction,
  getBlock,
  getAccInfo,
  formatCompactAddress,
  getExplorer,
  getAssetImage,
  fetchAssetImage,
  getAssetImageFromUrl
} from '../functions'
import { useSnackbar } from 'notistack';
import AuctionLoader from '../loaders/AuctionLoader';
import ContractLoader from "../loaders/ContractLoader";
import AuctionView from '../views/AuctionView'
import Card from '../components/Card'
import { Button, Col, Image, Row, Table } from "react-bootstrap";
import GalleryCard from "../components/GalleryCard";
import styles from './auction.module.css'
import md5 from "blueimp-md5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import appService from "../service/appService";
import offerService from "../service/offerService";
import { Badge } from "@mui/material";
import BasicModal from "../components/BasicModal";
import Icon from 'react-crypto-icons'
import { default as collections } from '../hooks/useCollection'

const Collection = (props) => {
  const history = useHistory()
  const WAValidator = require('@swyftx/api-crypto-address-validator')
  document.title = "Collection - NFT Jam"
  const {
    stdlib,
    acc,
    approvalProgram,
    firstBlock,
    ADDR_PLATFORM,
    ADDR_DISCOVERY,
    providerEnv
  } = props
  let { addr } = useParams()
  const isCollection = addr in collections
  const isAlgoAddress = addr
    && WAValidator.validate(addr, 'algo')
  const isAppId = false
  const [apps, setApps] = useState(isAppId ? [addr] : [])
  const [offers, setOffers] = useState([])
  const [asset, setAsset] = useState({})
  const [offerMap, setOfferMap] = useState({})
  const [collection, setCollection] = useState(null)
  const [showOfferModal, setShowOfferModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    addr,
    ctc: false,
    view: null,
    navigation: Boolean(addr) && !isNaN(parseInt(addr)) && !String(addr).match(/[^0-9]/),
    caughtUp: true,
    joined: true,
    once: true,
    bids: []
  })
  const explorer = getExplorer()

  console.log({ addr, isAlgoAddress, isAppId })
  // EFFECT
  /*
  useEffect(() => {
    if(!collection) return
    setLoading(true)
    collection.map(async el => {
      let key = `image-${el.index}`
      let image
      let storedImage = localStorage.getItem(key)
      if(!storedImage) {
        let res = await getAssetImageFromUrl(el?.params?.url)
        localStorage.setItem(key, res)
        image = res
      } else {
        image = storedImage
      }
      console.log(image)
    })
    setLoading(false)
  }, [collection])
  */
  useEffect(() => {
    if (collection) return;
    (async () => {
      if (isCollection) {
        /*
        let createdAssets = []
        for (let i in collections[addr]) {
          let accInfo = await getAccountInfo(collections[addr][i])
          createdAssets.push(accInfo['created-assets'])
        }
        setCollection(createdAssets.flatMap(el => el))
        */
        let accInfo = await getAccountInfo(collections[state.addr][0])
        let createdAssets = accInfo['created-assets']
        console.log(createdAssets)
        setCollection(createdAssets)
      }
      else if (isAlgoAddress) {
        let accInfo = await getAccountInfo(state.addr)
        let createdAssets = accInfo['created-assets']
        console.log(createdAssets)
        setCollection(createdAssets)
      }
    })()
  }, [collection, state.addr])
  useEffect(() => {
    if(!collection) return
    (async () => {
      let offerMap = {}
      // get offers from constructors
      let liveAppIds = []
      let [, , , offers] = appService.get()
      let [, addrs] = offers
      for (let i in addrs) {
        let addr = addrs[i]
        console.log({ addr })
        try {
          let createdApps = ((await getAccInfo(addr))?.data ?? {})['created-apps']
          liveAppIds.push(createdApps.map(el => el.id))
        } catch (e) { }
      }
      liveAppIds = liveAppIds.flatMap(el => el)
      console.log(liveAppIds)
      // get offers from api
      let offerList = await offerService.get({ creator: addr })
      for (let i in offerList) {
        let { appId, assetId } = offerList[i]
        if (!liveAppIds.includes(appId))
          continue
        if (assetId in offerMap) {
          offerMap[assetId].push(offerList[i])
        } else {
          offerMap[assetId] = [offerList[i]]
        }
      }
      setOfferMap(offerMap)
    })()
  }, [collection, state.addr])


  const getOffers = assetId => {
    if (!(assetId in offerMap)) return 0
    return offerMap[assetId].length
  }

  /*
   * handleKeyPress
   * - handles input from auction search
   */
  const handleKeyPress = async (event) => {
    let { key, target } = event
    let { value } = target
    const isAlgoAddress =
      WAValidator.validate(value, 'algo')
    const isAppId = !isAlgoAddress
      && /^[0-9]+$/.test(value)
    if (isAlgoAddress) {
      let addr = value
      let ids = (await Promise.all([
        (async addr => {
          let tx = ((await getAccountTransaction(addr))?.data?.transactions ?? []).pop()
          let round = (tx ?? {})['confirmed-round'] ?? -1
          let sender = (tx ?? {})['sender'] ?? ""
          let res = ((((await getBlock(round))?.data?.transactions ?? []).filter(el => el['tx-type'] === 'appl' && el['sender'] === sender)[0] ?? {})['application-transaction'] ?? {})['application-id'] ?? -1
          return res > 0 ? [res] : []
        }),
        (async addr => {
          let accInfo = await getAccountInfo(addr)
          let apps = accInfo['created-apps']
            .filter(app => approvalProgram === md5(app?.params['approval-program']))
            .map(({ id }) => id)
          return apps
        })
      ].map(el => el(addr)))).flatMap(el => el)
      if (ids.length > 0) {
        history.push(`/auction/${addr}`)
        setApps(ids)
      }
    }
    else if (isAppId) {
      let addr = value
      //let candidatePath = `/auction/${addr}`
      if (key === 'Enter') { //&& location.pathname !== candidatePath) {
        try {
          await getAppInfo(addr)
          history.push(`/auction/${addr}`)
          setApps([addr])
        } catch (e) {
          alert(`Auction ${addr} not found`)
        }
      }
    }
  }

  const searchBarStyle = {
    /*"width": "540px",*/
    "height": "60px",
    "background": "#FFFFFF",
    "border": "1px solid #C9C9C9",
    "boxSizing": "border-box",
    "boxShadow": "0px 8px 28px rgba(0, 0, 0, 0.10)",
    "borderRadius": "52px",
    "display": "flex"
  }
  const searchCardStyle =
  {
    "height": "277px",
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "margin": "auto"
  }
  const compactAuctionSearchCardStyle =
  {
    ...searchCardStyle,
    "width": "90vw",
    "padding": "50px 30px",
  }
  const auctionSearchCardStyle =
  {
    ...searchCardStyle,
    "width": "640px",
    "padding": "50px",
  }
  const auctionTitleStyle =
  {
    "height": "38px",
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "900",
    "fontSize": "32px",
    "lineHeight": "38px",
    "textAlign": "center",
    "letterSpacing": "0.1em",
    "textTransform": "uppercase",
    "color": "#2A3035",
    "opacity": "0.8",
  }
  const auctionDescriptionStyle = {
    "display": "none"
  }
  const auctionSearchContainerStyle =
  {
    "marginTop": "79px",
  }
  const auctionSearchbarInputStyle =
  {
    "width": "85%",
    "border": "0",
    "marginLeft": "15px",
    "borderRadius": "52px"
  }
  const auctionSearchbarInputTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "bold",
    "fontSize": "14px",
    "lineHeight": "17px",
    "letterSpacing": "0.05em",
    "color": "#484959"
  }
  const auctionStyle =
  {
    "margin": "auto",
    "paddingBottom": "100px"
  }
  const navbarBrandTypographyStyle =
  {
    "color": "black",
    "paddingTop": "8px",
    "paddingBottom": "8px",
    "fontFamily": "Roboto",
    "fontSize": "12px",
    "fontStyle": "normal",
    "fontWeight": "600",
    "lineHeight": "14px",
    "letterSpacing": "0em",
    "textAlign": "left"
  }
  const myAuctionStyle =
  {
    "width": "100px",
    "height": "14px",
    "fontFamily": "Roboto",
    "fontStyle": "normal",
    "fontWeight": "500",
    "fontSize": "12px",
    "lineHeight": "14px",
    "color": "rgb(85, 89, 93)"
  }
  return <FeatureFlagContext.Consumer>
    {({
      myAuctions,
      auctionSearch,
    }) => <div id="auction" style={auctionStyle}>
        <BasicModal open={showModal} handleOpen={() => setShowModal(true)} handleClose={() => setShowModal(false)}>
          <Row>
            <Col xs={12}>
              <LazyLoadImage src={asset?.params?.url} fluid />
            </Col>
            <Col xs={12} sm={6}>
              <span>ASSET ID:</span>
              <a style={{ float: 'right' }} href={`${explorer}/asset/${asset?.index}`} target="_blank" rel="noopener noreferrer">{asset?.index}</a>
            </Col>
          </Row>
        </BasicModal >
        <BasicModal open={showOfferModal} handleOpen={() => setShowOfferModal(true)} handleClose={() => setShowOfferModal(false)}>
          <Row style={{ width: '50vw' }}>
            <Col xs={12}>
              <Table className="w-100" borderless striped>
                <tbody>
                  {offers.map(el =>
                    <tr>
                      <td style={{ verticalAlign: "middle" }}><Icon name="algo" size="12" /> {el?.app?.currentPrice}</td>
                      <td style={{ verticalAlign: "middle" }}>{formatCompactAddress(el?.app?.highestBidder)}</td>
                      <td style={{ textAlign: "right" }}><Button onClick={() => {
                        history.push(`/offer/${el.appId}`)
                      }}>VIEW OFFER</Button></td>
                    </tr>)}
                </tbody>
              </Table>
            </Col>
          </Row>
        </BasicModal >
        {auctionSearch && [
          {
            style: auctionSearchCardStyle,
            className: "d-none d-sm-block"
          },
          {
            style: compactAuctionSearchCardStyle,
            className: "d-xs-d-block d-sm-none"
          }
        ].map(props =>
          <div {...props}>
            <div style={auctionTitleStyle}>Auction</div>
            <div style={auctionDescriptionStyle}>&nbsp;</div>
            <div className="search" style={{ ...searchBarStyle, ...auctionSearchContainerStyle }}>
              <input
                className="search-bar"
                style={{ ...auctionSearchbarInputStyle, ...auctionSearchbarInputTypographyStyle }}
                placeholder="Search auction: Enter NFT Auction App Id"
                //defaultValue={addr} 
                onKeyPress={handleKeyPress} />
            </div>
          </div>)}
        {myAuctions && acc?.appInfo && acc?.appInfo.length > 0 && <div style={{
          "margin": "42px auto",
          "columnGap": "5vw",
          "display": "flex",
          "alignItems": "center",
          "padding": "10px",
          "borderRadius": "10px",
          "background": "rgba(0, 0, 0, 0) linear-gradient(106.65deg, rgb(177, 255, 130) -6.26%, rgb(62, 249, 170) 124.55%) repeat scroll 0% 0%",
        }}>
          <div style={navbarBrandTypographyStyle}>My Auctions</div>
          <div style={{
            "columnGap": "1vw",
            "display": "flex",
            "rowGap": "10px",
            "flexWrap": "wrap",
            "justifyContent": "center"
          }}>
            {acc && acc.appInfo.map(el =>
              <span
                role="button"
                onClick={() => {
                  window.location = `/auction/${el.id}`
                  // TODO: bring this back
                  /*
                  history.push(`/auction/${addr}`)
                  setState({
                    view: null,
                    ctc: false,
                    once: false,
                    navigation: true
                  })
                  */
                }}
                style={myAuctionStyle}>
                {el.id == addr
                  ? (<span style={{ "fontWeight": "900", "color": "rgb(199, 90, 243)" }}>{el.id}</span>)
                  : (<span className="my-auction-list-item" >{el.id}</span>)}
              </span>)}
          </div>
        </div>}
        <div className="mb-3" style={{ ...auctionTitleStyle, color: 'white' }}>{isAlgoAddress ? formatCompactAddress(addr) : addr?.replace(/-/g, ' ')}</div>
        {addr ?
          !loading ? <Row>
            {collection
              ?.filter(el => el?.params?.url)
              ?.map(el =>
                <Col xs={6} sm={4} key={el.index} className="border text-light bg-dark">
                  <Badge onClick={(e) => {
                    setOffers(offerMap[el.index])
                    setShowOfferModal(true)
                  }} badgeContent={getOffers(el.index)} color="primary">
                    <div onClick={() => {
                      history.push(`/asset/${el.index}`)
                    }}>
                      {providerEnv === 'MainNet' && <Image as={LazyLoadImage} className="mt-3" src={`/images/${el.index}-thumb`} fluid/>}
                      <div>
                      {el.index}<br />
                      {el?.params?.name} {el?.params["unit-name"]}<br />
                      1 of {(el?.params?.total || 1) * Math.pow(10, el?.params?.decimals)}
                      </div>
                    </div>
                  </Badge>
                </Col>)}
          </Row> : "loading..."
          : <>
            <div className="mb-3" style={{ ...auctionTitleStyle, color: 'white' }}>Collections</div>
            <Row>
              {Object.entries(collections)?.map(([name, [addrs, index]]) => (label => 
              <Col className="p-3 border bg-dark text-light" onClick={() => {
                /*
                setCollection(null)
                history.push(`/collection/${name}`)
                */
              }} key={name}>
                {providerEnv === 'MainNet' && <Image as={LazyLoadImage} src={`/images/${index}-thumb`} fluid />}
                <div onClick={() => {
                 setCollection(null)
                 setState({...state, addr: label.toLocaleLowerCase().replace(/ /g,'-') })
                 history.push(`/collection/${label.toLocaleLowerCase().replace(/ /g,'-')}`) 
                }}>{label}</div>
                {false && addrs.map(addr => <div onClick={() => {
                  setCollection(null)
                  setState({...state, addr })
                  history.push(`/collection/${addr}`)
                }}>{formatCompactAddress(addr)}</div>)}
              </Col>)(String(name.replace(/-/g, ' ')).toUpperCase()))}
            </Row></>}
      </div>}
  </FeatureFlagContext.Consumer>
}

export default Collection;