import * as backend from '../build/token.main.mjs'
import moment from "moment"
import { useState } from "react"
import {
  formatCompactAddress,
} from "../functions";
import JoinButton from "../components/buttons/JoinButton";
import Countdown from '../components/Countdown'
import BidHistory from "../components/BidHistory";
import TokenHistoryLoader from "../loaders/TokenHistoryLoader";
import TokenButtonLoader from "../loaders/TokenButtonLoader";
import { Col, Image, Row } from "react-bootstrap";
import BasicModal from "../components/BasicModal";
import DonateAPIButtons from "../components/buttons/DonateAPIButton";
import AuctionIndicators from '../components/AuctionIndicators'
import useCopy from '../hooks/useCopy';
import IndicatorLoader from '../loaders/IndicatorLoader';
import DeleteButton from '../components/buttons/DeleteButton';
import { getExplorer } from '../functions';
import { FeatureFlagContext } from '../context/FeatureFlagContext';
const bidStyle = {
  "padding": "24px",
  "background": "#F3F3F3",
  "color": "#888888",
  "fontFamily": "Roboto",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "12px",
  "lineHeight": "14px"
}
const textLeftStyle = {
  "textAlign": "left"
}
const valueStyle =
{
  "height": "17px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "bold",
  "fontSize": "14px",
  "lineHeight": "17px",
  "color": "#C75AF3",
  "flex": "none",
  "order": "1",
  "flexGrow": "0",
  "margin": "0px 2px"
}
const labelStyle =
{
  "height": "17px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "300",
  "fontSize": "14px",
  "lineHeight": "17px",
  "color": "#484959",
  "flex": "none",
  "order": "0",
  "flexGrow": "0",
  "margin": "0px 2px"
}
const AuctionView = (props) => {
  const {
    ctc,
    onClose,
    addr,
    stdlib,
    platformAddr,
    closed,
    appId,
    token,
    owner,
    creator,
    endSecs,
    decimals
  } = props
  const copy = useCopy()
  const [loading, setLoading] = useState(props.loading)
  const auctionViewCardStyle =
  {
    //"height": "333px",
    "background": "#FFFFFF",
    "boxShadow": "0px 2px 12px rgba(0, 0, 0, 0.1)",
    //"borderRadius": "12px",
    "borderRadius": "36px",
    "overflow": "hidden",
    "width": "90vw",
    "margin": "42px auto"
  }
  const NFTNameStyle =
  {
    //"height": "33px",
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "bold",
    "fontSize": "28px",
    "lineHeight": "33px",
    "color": "#55595D"
  }
  const appIdStyle =
  {
    "height": "17px",
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "14px",
    "lineHeight": "17px",
    "textAlign": "right",
    "color": "#55595D"
  }
  const ownerStyle =
  {
    "height": "17px",
    "fontFamily": "Rubik",
    "fontStyle": "italic",
    "fontWeight": "300",
    "fontSize": "14px",
    "lineHeight": "17px",
    "color": "#484959",
    "marginTop": "8px",
    "display": "inline-flex",
    "columnGap": "4px"
  }
  const valueContainerStyle =
  {
    "marginTop": "15px"
  }
  const descriptionLabelStyle =
  {
    "height": "14px",
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "300",
    "fontSize": "12px",
    "lineHeight": "14px",
    "color": "#C558F3",
    "marginTop": "16px"
  }
  const descriptionStyle =
  {
    "width": "95%",
    //"height": "161px",
    "background": "#FCFCFC",
    "border": "1px solid #F2F2F2",
    "boxSizing": "border-box",
    "borderRadius": "16px",
    "margin": "8px auto",
    "padding": "14px",
    "height": "161px",
    "overflow": "hidden"
  }

  const NFTImage = (props) => {
    const [showModal, setShowModal] = useState(false)
    const explorer = getExplorer()
    return <>
      <BasicModal open={showModal} handleOpen={() => setShowModal(true)} handleClose={() => setShowModal(false)}>
        <Row>
          <Col xs={12} sm={6}>
            <Image src={props.image} fluid />
          </Col>
          <Col xs={12} sm={6}>
            <Row>
              <Col xs={12}>
                <span>APP ID:</span><a style={{ float: "right" }} href={`${explorer}/application/${appId}`} target="_blank" rel="noopener noreferrer">{appId}</a>
              </Col>
              <Col xs={12}>
                <span>ASSET ID:</span><a style={{ float: "right" }} href={`${explorer}/asset/${token}`} target="_blank" rel="noopener noreferrer">{token}</a>
              </Col>
              <Col xs={12}>
                <span>ESCROW:</span><a style={{ float: "right" }} href={`${explorer}/address/${addr}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(addr)}</a>
              </Col>
              <Col xs={12}>
                <span>CREATOR:</span><a style={{ float: "right" }} href={`${explorer}/address/${creator}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(creator)}</a>
              </Col>
              <Col xs={12}>
                <span>OWNER:</span><a style={{ float: "right" }} href={`${explorer}/address/${owner}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(owner)}</a>
              </Col>
              <Col xs={12}>
                <span>END:</span><span style={{ float: "right" }}>{moment.unix(endSecs).format("LL")}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </BasicModal>
      <Col
        onClick={() => setShowModal(true)}
        className="d-xs-block d-md-none"
        style={{
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          padding: '0px'
        }} xs={12} md={3}>
        <Image src={props.image} alt="NFT" fluid />
      </Col>
      <Col
        onClick={() => setShowModal(true)}
        className="d-none d-md-block" style={{
          backgroundImage: `url(${props.image})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }} xs={12} md={3}>
        &nbsp;
      </Col>
    </>
  }
  return <FeatureFlagContext.Consumer>
    {({
      deleteButton,
    }) => <>
        <Row style={auctionViewCardStyle}>
          <NFTImage image={props.image} />
          <Col style={textLeftStyle} xs={12} md={6}>
            <Row>
              <Col style={{ "marginTop": "24px" }} xs={12}>
                <Row>
                  <Col xs={12} sm={8} style={NFTNameStyle}>
                    {props.name}
                  </Col>
                  <Col className={"d-none d-sm-block"} sm={4} style={appIdStyle}>
                    ID: {appId}
                  </Col>
                  <Col style={ownerStyle} xs={12}>
                    {props.creator === props.owner
                      ? <>
                        <span style={{ cursor: "pointer" }} onClick={() => copy(props.creator)}>{formatCompactAddress(props.creator)}</span>
                        <span className={"d-xs-block d-sm-none"}>{'/'} {appId}</span>
                      </>
                      : <>
                        <span style={{ cursor: "pointer" }} onClick={() => copy(props.creator)}>{formatCompactAddress(props.creator)}</span>{'/'}
                        <span style={{ cursor: "pointer" }} onClick={() => copy(props.owner)}>{formatCompactAddress(props.owner)}</span>
                        <span className={"d-xs-block d-sm-none"}>{'/'} {appId}</span>
                      </>}
                  </Col>
                </Row>
              </Col>
              <Col style={valueContainerStyle} xs={12}>
                {false && <span style={labelStyle}>Amount of NFTs:<span style={valueStyle}>{props.circulatingsupply}</span></span>}
                {true && <span style={labelStyle}>Rarity:<span style={valueStyle}>1 of {props.total} available</span></span>}
                <Countdown initialEndMoment={moment.unix(props.endSecs)} v={props.v} stdlib={props.stdlib} />
              </Col>
              <Col xs={12}>
                <IndicatorLoader
                  decimals={props.decimals}
                  id={props.appId}
                  v={props.v}
                  initialState={{
                    reservePrice: props.reservePrice,
                    roy: props.creator === props.owner ? 0 : props.roy,
                    isReservePriceMet: parseFloat(props.currentPrice) > parseFloat(props.reservePrice),
                    isClosed: parseInt(props.endSecs) < moment().unix(),
                  }} stdlib={stdlib}>
                  <AuctionIndicators />
                </IndicatorLoader>
              </Col>
              {props.description && <>
                <Col style={descriptionLabelStyle} xs={12}>
                  Description
                </Col>
                <Col style={descriptionStyle} xs={12}>
                  <p>
                    {props.description}
                  </p>
                </Col>
              </>}
            </Row>
          </Col>
          <Col style={{
            ...bidStyle,
            ...textLeftStyle,
            "height": "400px",
            //"display": "flex",
            //"alignItems": "center"
          }} xs={12} md={3}>
            <Row>
              <TokenHistoryLoader
                decimals={props.decimals}
                token={props.token}
                id={props.appId}
                acc={props.acc}
                ctc={ctc}
                addr={addr}
                stdlib={stdlib}
                platformAddr={platformAddr}
                initialIsAuctionOver={props.isClosed}
                initialBidHistory={props.bidHistory}
                initialLastBid={props.lastBid}>
                <BidHistory iconName="btc" unitname={props.unitname} address={props.acc.address} />
              </TokenHistoryLoader>
              {<Col style={{ ...labelStyle, "marginTop": "19.5px", "padding": "0px", "marginBottom": "20px" }} xs={12}>
                {props.showJoin
                  ? <JoinButton />
                  : <TokenButtonLoader
                    decimals={props.decimals}
                    ctc={props.ctc}
                    onClose={onClose}
                    stdlib={stdlib}
                    initialState={{
                      recvAddr: props.recvAddr,
                      showing:
                        props.recvAddr === props.address
                          ? "claim"
                          : props.endSecs < moment().unix()
                            ? (
		      /*reserveBidMet*/ parseFloat(props.currentPrice) > parseFloat(props.reservePrice)
                                ? (
                                  props.highestBidder === props.address
                                    ? "claim"
                                    : "close")
                                : props.fHb === props.address || props.owner === props.address
                                  ? "close"
                                  : "buy")
                            : "bid",
                      closed: props.closed,
                    }}
                    acc={props.acc}
                    id={props.appId}
                    a={props.a}
                    apis={props.apis}
                    owner={props.owner}
                    v={props.v}>
                    {!props.isClosed && <DonateAPIButtons
                      recvAddr={props.recvAddr}
                      unitname={props.unitname}
                      tokenP={props.tokenP}
                      decimals={props.decimals}
                      ctc={props.ctc}
                      acc={props.acc}
                      address={props.address}
                      stdlib={stdlib}
                      onClose={onClose}
                      showing={
                        props.recvAddr === props.address
                          ? "claim"
                          : props.endSecs < moment().unix()
                            ? (
			    /*reserveBidMet*/ parseFloat(props.currentPrice) > parseFloat(props.reservePrice)
                                ? (
                                  props.highestBidder === props.address
                                    ? "claim"
                                    : "close")
                                : props.highestBidder === props.address || props.owner === props.address
                                  ? "close"
                                  : "buy")
                            : "bid"}
                      views={props.v}
                      apis={props.apis}
                      appId={props.appId}
                      highestBidder={props.highestBidder}
                      reservePrice={props.reservePrice}
                      reservePriceMet={parseFloat(props.currentPrice) > parseFloat(props.reservePrice)}
                      startPrice={props.startPrice}
                      bidDefaultValue={Math.max(props.minBid, props.startPrice)} />}
                    {deleteButton && props.address === props.owner && <DeleteButton ctc={props.ctc} />}
                  </TokenButtonLoader>}
              </Col>}
            </Row>
          </Col>
        </Row>
        {/*testing*/ !true && <>
          <DonateAPIButtons
            recvAddr={props.recvAddr}
            decimals={props.decimals}
            ctc={props.ctc}
            acc={props.acc}
            address={props.address}
            stdlib={stdlib}
            onClose={onClose}
            showing="close"
            views={props.v}
            apis={props.apis}
            appId={props.appId}
            highestBidder={props.highestBidder}
            reservePriceMet={parseFloat(props.currentPrice) > parseFloat(props.reservePrice)}
            startPrice={props.startPrice}
            bidDefaultValue={Math.max(parseInt(props.startPrice), parseInt(props.currentPrice) + 1)} />
          <button style={{ "marginTop": "19.5px", "padding": "10px", "borderRadius": "44px" }} onClick={() => backend.Relay(ctc, { close: () => { } })}>Delete</button>
        </>}
      </>}
  </FeatureFlagContext.Consumer>
}
export default AuctionView;