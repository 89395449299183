import {
  useHistory,
} from "react-router-dom";
import Button from 'react-bootstrap/Button'
import nftjam from '../nftjam.svg'

const Home = ({
  feature: {
    sellPage
  }
}) => {
  document.title = "NFT Jam"
  let history = useHistory();
  const serviceDescriptionStyle =
  {
    "marginTop": "16px"
  }
  const serviceDescriptionTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "24px",
    "lineHeight": "28px",
    "textAlign": "center",
    "textTransform": "uppercase"
  }
  const serviceButtonGroupStyle =
  {
    "marginTop": "44px",
    "display": "flex",
    "columnGap": "1em",
    "justifyContent": "center"
  }
  const buttonTypographyStyle = {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "14px",
    "textAlign": "center",
    "textTransform": "uppercase"
  }
  const buttonStyle =
  {
    "display": "inline-block",
    "width": "169px",
    "height": "50px",
    "lineHeight": "50px",
    "color": "white"
  }
  const sellButtonStyle =
  {
    "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
    "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
    "borderRadius": "44px"
  }
  const auctionButtonStyle =
  {
    "background": "linear-gradient(128.67deg, #FF82A0 -29.38%, #F93E70 100.49%)",
    "boxShadow": "0px 10px 20px rgba(255, 124, 158, 0.51)",
    "borderRadius": "44px"
  }
  const homeStyle =
  {
    "position": "fixed",
    "top": "30%"
  }
  return <div id="home" style={homeStyle}>
    <div>
      <img src={nftjam} alt="NFT Jam" />
    </div>
    <div style={{ ...serviceDescriptionTypographyStyle, ...serviceDescriptionStyle }}>
      Algorand NFT Auction for Resellers
    </div>
    <div style={{ ...serviceButtonGroupStyle }}>
      {sellPage
        ? <span role="button" style={{ ...buttonStyle, ...buttonTypographyStyle, ...sellButtonStyle }} onClick={() => history.push('/sell')}>Sell My NFT</span>
        : <span role="button" style={{ ...buttonStyle, ...buttonTypographyStyle, ...sellButtonStyle }} onClick={() => history.push('/browse?filter=open')}>Sell My NFT</span>}
      <span role="button" style={{ ...buttonStyle, ...buttonTypographyStyle, ...auctionButtonStyle }} onClick={() => history.push('/browse')}>View Auction</span>
    </div>
  </div>
}

export default Home;