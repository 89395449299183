import { FeatureFlagContext } from "./context/FeatureFlagContext";
import React, { StrictMode, useEffect, useState } from "react";
import { loadStdlib } from "@reach-sh/stdlib";
import MyAlgoConnect from "@reach-sh/stdlib/ALGO_MyAlgoConnect";
import WalletConnect from "@reach-sh/stdlib/ALGO_WalletConnect";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Sell from "./pages/Sell";
import SellAuction from "./pages/SellAuction";
import SellReverse from "./pages/SellReverse";
import SellToken from "./pages/SellToken";
import SellDonate from "./pages/SellDonate";
import Buy from "./pages/Buy";
import Auction from "./pages/Auction";
import Reverse from "./pages/Reverse";
import Offer from "./pages/Offer";
import Token from "./pages/Token";
import Donate from "./pages/Donate";
import Faq from "./pages/Faq";
import Browse from "./pages/Browse";
import Asset from "./pages/Asset";
import Navbar from "./components/Navbar";
import Container from "react-bootstrap/Container";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import "./App.css";
//import useGaTracker from './hooks/useGaTracker'
import queryString from "query-string";
import md5 from "blueimp-md5";
import icons from "./icons.json";
import Collection from "./pages/Collection";
import collection from "./hooks/useCollection";
import appService from "./service/appService";

const axios = require("axios");

const APP_ID_BANK = 27856254;
const ASSET_ID_JAM = 27735901;
const FIRSTBLOCK = { TestNet: 18477441, MainNet: 17486193 };
const versions = {
  auction: [
    [
      "jam-0.6.6r3d",
      { TestNet: 18477441, MainNet: 17644090 },
      "1f5c14ae39584b984d055ecee4cf2189",
    ], // reach v0.1.7
  ],
  reverse: [
    [
      "rev-0.0.2",
      { TestNet: 18435364, MainNet: 17644090 },
      "a2837ed86887fcc78192f561712fc975",
    ], // use relay
  ],
  offer: [
    [
      "off-0.0.1",
      { TestNet: 18115083, MainNet: 17644090 },
      "88201644222c82ba5433e22ff60e2bf4",
    ], // offer
  ],
  token: [
    [
      "tok-0.0.1",
      { TestNet: 18115083, MainNet: 17644090 },
      "7c17eb1d50cb9f57cd5f229a433a9e83",
    ], // asa auction
  ],
  donate: [
    [
      "don-0.0.1",
      { TestNet: 18489038, MainNet: 17644090 },
      "09dbcff22d8d6c9aaa2cd6d39dd3a4fb",
    ], // donate
  ],
};
const { auction, reverse, offer, token, donate } = versions;
const [appVersion, firstBlock, approvalProgram] = auction[0];
const [reverseAppVersion, reverseFirstBlock, reverseApprovalProgram] =
  reverse[0];
const [offerAppVersion, offerFirstBlock, offerApprovalProgram] = offer[0];
const [tokenAppVersion, tokenFirstBlock, tokenApprovalProgram] = token[0];
const [donateAppVersion, doanteFirstBlock, donateApprovalProgram] = donate[0];
const [
  ADDR_PLATFORM_DEFAULT, // AUCTION
  ADDR_PLATFORM_DEFAULT2, // REVERSE
  ADDR_PLATFORM_DISCOVERY, // AUCTION
  ADDR_PLATFORM_DISCOVERY2,
  ADDR_PLATFORM_DISCOVERY3, // REVERSE
  ADDR_PLATFORM_SHARED,
  ADDR_PLATFORM_PRIVATE,
  ADDR_PLATFORM_DISCOVERY4, // TOKEN
  ADDR_PLATFORM_DEFAULT3, // TOKEN
] = [
  "7GDBWS6G5YB5TS4SYTY5IRKHYR5G7JZIAAIGNNSV3VFQJ7MOA3EZZ3QLFI", // AUCTION
  "VJ32JXG6JVRUX5YUTXFSXLMV5LWIRBMXWBHIJPNRMUSSUVAGCBP6QFFFXI", // REVERSE
  "J5UB2NODM4GLI5ID2VYPT6DT6D2CRGJBCRCDYA67SC2754SZTH5R7FXRF4", // AUCTION
  "EWHAS2U34VKLIVTWLJ4VOIXI2GTH5IIHVSTUNO52UPYOKTEAXFO7OPLHFE",
  "BFMP2QFF452X3ELUGITKGUHFNTPSJZOSHHVGLC5S5G2ZAWWVM7ON3DGO5I", // REVERSE
  "WDJSPRRCX54EST6HHLPZEJD36JR456VO6U3W7647TDIFET72LS6FHAREIA",
  "ZM62PGOING7KM3UOXJJDRA3IDOYWC6RYAX2JBCF7PPZZXNCO6XLU3KR4WY",
  "I34YFUFJVMDWV5QTKXFT33CGPEPTGSKB4LMJX5N6ZSRXWJV5MPYULTWG7A", // TOKEN (DISCOVERY)
  "HZXRSUPTJB3UGEW4TJTQLCC6T3KY5K6EHZ2QPR2HY4R6EXH7LRPPC5NUKA", // TOKEN (PLATFORM)
];

// eslint-disable-next-line no-unused-vars
const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env;
const networkEnv = REACT_APP_NETWORK || "ALGO";
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet";
let algoexplorerapi_endpoint;
if (providerEnv === "MainNet") {
  algoexplorerapi_endpoint = "https://algoexplorerapi.io";
} else {
  algoexplorerapi_endpoint = "https://testnet.algoexplorerapi.io";
}

const stdlib = loadStdlib(networkEnv);
// ALGO
if (networkEnv === "ALGO") {
  if (!localStorage.getItem("walletFallback")) {
    localStorage.setItem("walletFallback", "Mnemonic");
  }
  if (!localStorage.getItem("settingsWalletFallback")) {
    localStorage.setItem("settingsWalletFallback", "MyAlgoConnect");
  }
  if (localStorage.getItem("walletFallback") === "MyAlgoConnect") {
    stdlib.setWalletFallback(
      stdlib.walletFallback({
        providerEnv,
        MyAlgoConnect,
      })
    );
  } else if (localStorage.getItem("walletFallback") === "WalletConnect") {
    stdlib.setWalletFallback(
      stdlib.walletFallback({
        providerEnv,
        WalletConnect,
      })
    );
  } else {
    stdlib.setWalletFallback(
      stdlib.walletFallback({
        providerEnv,
      })
    );
  }
}

function App(props) {
  const WAValidator = require("@swyftx/api-crypto-address-validator");
  let history = useHistory();
  let location = useLocation();
  const { url } = queryString.parse(location?.search);
  if (url) {
    let [path, id] = url.split("/").slice(1);
    console.log([path, id]);
    switch (path) {
      case "browse":
      case "faq":
      case "sell":
        history.push(`/${path}`);
        break;
      case "collection":
      case "asset":
      case "sell-auction":
      case "sell-reverse":
      case "sell-token":
      case "sell-donate":
      case "auction":
      case "reverse":
      case "token":
      case "offer":
      case "buy":
      case "donate":
        if (
          !isNaN(parseInt(id)) ||
          WAValidator.validate(id || "", "algo") ||
          id in collection
        ) {
          history.push(`/${path}/${id}`);
        } else {
          history.push(`/${path}`);
        }
        break;
      default:
        break;
    }
  }
  //useGaTracker()
  const [state, setState] = useState({
    APP_ID_BANK,
    ASSET_ID_JAM,
    ADDR_MNEMONIC: ADDR_PLATFORM_SHARED,
    ADDR_PLATFORM: ADDR_PLATFORM_DEFAULT, // AUCTION
    ADDR_PLATFORM2: ADDR_PLATFORM_DEFAULT2, // REVERSE
    ADDR_PLATFORM3: ADDR_PLATFORM_DEFAULT3, // TOKEN
    ADDR_DISCOVERY: ADDR_PLATFORM_DISCOVERY, // AUCTION
    ADDR_DISCOVERY2: ADDR_PLATFORM_DISCOVERY2,
    ADDR_DISCOVERY3: ADDR_PLATFORM_DISCOVERY3, // REVERSE
    ADDR_DISCOVERY4: ADDR_PLATFORM_DISCOVERY4, // TOKEN
    ADDR_PRIVATE: ADDR_PLATFORM_PRIVATE,
    once: false,
    acc: null,
    method: localStorage.getItem("walletFallback") || "Mnemonic",
    stdlib,
    using: "MyAlgoConnect",
    approvalProgram,
    reverseApprovalProgram,
    offerApprovalProgram,
    tokenApprovalProgram,
    donateApprovalProgram,
    providerEnv,
    firstBlock: firstBlock[providerEnv],
    FIRSTBLOCK: FIRSTBLOCK[providerEnv],
    reverseFirstBlock: reverseFirstBlock[providerEnv],
    REVERSEFIRSTBLOCK: FIRSTBLOCK[providerEnv],
    offerFirstBlock: offerFirstBlock[providerEnv],
    OFFERFIRSTBLOCK: FIRSTBLOCK[providerEnv],
    icons,
    //versions
  });
  const connectAccountUsingLocalStorage = async () => {
    let acc = null;
    try {
      let addr = localStorage.getItem("addr");
      acc = await stdlib.connectAccount({ addr });
    } catch (e) {
      console.log({ e });
    }
    return acc;
  };
  const connectAccountUsingDefaultAccount = async () => {
    let acc = null;
    try {
      acc = await stdlib.getDefaultAccount();
      console.log({ acc });
      localStorage.setItem("addr", acc.networkAccount.addr);
    } catch (e) {
      console.log({ e });
      console.log(4);
    }
    return acc;
  };
  const connectAccount = async (method = "Default") => {
    console.log("Getting account ...");
    console.log({ method });
    let acc;
    console.log(2);
    switch (method) {
      case "Mnemonic":
        acc = await stdlib.newAccountFromMnemonic(
          "prize cube acquire asset thing pipe school craft story enforce tunnel hand fun forget tonight boss pink thank force orchard flat invest rule absent plate"
        );
        break;
      case "LocalStorage":
        acc = await connectAccountUsingLocalStorage();
        break;
      case "Default":
      default:
        acc = await connectAccountUsingDefaultAccount();
    }
    if (!acc) return;
    console.log({ acc });
    let accInfo = (
      await axios.get(
        `${algoexplorerapi_endpoint}/v2/accounts/${acc?.networkAccount?.addr}`
      )
    )?.data;
    /* assetInfo */
    let assetInfo;
    assetInfo = [];
    /*
    assetInfo = (await Promise.all([
      ...accInfo?.assets?.filter(el => true)?.map(el =>
        axios.get(`${algoexplorerapi_endpoint}/idx2/v2/assets/${el["asset-id"]}`))
    ])).map(el => ({ ...el, ...(el?.data?.asset) }))
    */

    /* appInfo */
    let appInfo;
    /*
    let approvalPrograms = versions.map(([,,approvalProgram]) => approvalProgram)
    console.log({approvalPrograms})
    let appInfo = accInfo['created-apps'].filter(el => {
      let hash = md5(el?.params['approval-program'])
      console.log(hash)
      return approvalPrograms.includes(hash)
    })
    */
    appInfo = accInfo["created-apps"].filter((el) => {
      let hash = md5(el?.params["approval-program"]);
      return approvalProgram === hash;
    });
    return {
      ...acc,
      ...accInfo,
      assetInfo,
      appInfo,
    };
  };
  const disconnectAccount = () => {
    localStorage.removeItem("addr");
    localStorage.setItem("walletFallback", "Mnemonic");
    history.go("/");
    //setState({...state, acc: null})
  };

  useEffect(() => {
    if (!state.once) {
      (async () => {
        window.prompt = () => {}; // prevent acc2 prompt for account address
        setState({
          ...state,
          once: true,
          acc: await connectAccount(state.method),
        });
      })();
    }
  });
  const handleConnect = async () => {
    localStorage.setItem(
      "walletFallback",
      localStorage.getItem("settingsWalletFallback") || "MyAlgoConnect"
    );
    history.go();
    //let acc = await connectAccount()
    //setState({ ...state, acc, using: "asfd" })
  };
  const containerStyle = {
    alignItems: "baseline",
    display: "flex",
    justifyContent: "center",
    height: "100%",
  };
  return (
    <FeatureFlagContext.Consumer>
      {(feature) => (
        <LocalizationProvider dateAdapter={DateAdapter}>
          {providerEnv === "MainNet" ? (
            <span
              style={{
                background: "rgb(255, 127, 127)",
                color: "white",
                padding: "5px",
              }}
            >
              {providerEnv}
            </span>
          ) : (
            <span
              style={{
                background: "rgb(127, 255, 127)",
                color: "black",
                padding: "5px",
              }}
            >
              {providerEnv}
            </span>
          )}
          <div className="App">
            <Navbar
              {...state}
              handleConnect={handleConnect}
              handleDisconnect={disconnectAccount}
            />
            <Container style={containerStyle} size="lg" fluid="lg">
              <Switch>
                <Route exact path="/auction">
                  <Auction {...state} />
                </Route>
                <Route path="/auction/:appId">
                  <Auction {...state} />
                </Route>
                {feature.sellPage && (
                  <Route exact path="/sell">
                    <Sell {...state} />
                  </Route>
                )}
                {feature.sellPage && (
                  <Route exact path="/sell/auction">
                    <SellAuction {...state} />
                  </Route>
                )}
                {
                  <Route path="/sell-auction/:appId">
                    <SellAuction {...state} />
                  </Route>
                }
                <Route exact path="/sell/reverse">
                  <SellReverse {...state} />
                </Route>
                <Route path="/sell-reverse/:appId">
                  <SellReverse {...state} />
                </Route>
                <Route path="/sell-token/:appId">
                  <SellToken {...state} />
                </Route>
                <Route path="/sell-donate/:appId">
                  <SellDonate {...state} />
                </Route>
                {false && (
                  <Route exact path="/buy">
                    <Buy {...state} />
                  </Route>
                )}
                <Route path="/buy/:appId">
                  <Buy {...state} />
                </Route>
                <Route path="/token/:appId">
                  <Token {...state} />
                </Route>
                <Route path="/donate/:appId">
                  <Donate {...state} />
                </Route>
                <Route path="/reverse/:appId">
                  <Reverse {...state} />
                </Route>
                <Route path="/offer/:appId">
                  <Offer {...state} />
                </Route>
                <Route exact path="/collection">
                  <Collection {...state} />
                </Route>
                <Route path="/collection/:addr">
                  <Collection {...state} />
                </Route>
                <Route path="/asset/:index">
                  <Asset {...state} />
                </Route>
                {feature.browsePage && (
                  <Route exact path="/browse">
                    <Browse {...state} feature={feature} />
                  </Route>
                )}
                <Route exact path="/faq">
                  <Faq />
                </Route>
                <Route path="/">
                  <Home feature={feature} />
                </Route>
              </Switch>
            </Container>
          </div>
        </LocalizationProvider>
      )}
    </FeatureFlagContext.Consumer>
  );
}

export default App;
