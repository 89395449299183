import { Col } from "react-bootstrap"
import Icon from "react-crypto-icons"
import { formatCompactAddress } from "../functions"
import useCopy from "../hooks/useCopy"
const bidLabelStyle =
{
  "height": "14px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "300",
  "fontSize": "12px",
  "lineHeight": "14px",
  "color": "#C558F3",
  "padding": "0px"
}
const bidLabelStyle2 =
{
  "marginTop": "12px"
}
const bidAmountTypography = {
  "fontFamily": "Roboto",
  "fontStyle": "normal",
  "fontWeight": "bold",
  "fontSize": "12px",
  "lineHeight": "14px",
  "color": "#000000"
}
const bidHistoryStyle = {
  "background": "#FFFFFF",
  "borderRadius": "4px",
  "marginTop": "4px",
  "paddingBottom": "10px",
}
const currentBidAddressStyle =
{
  "width": "127px",
  "height": "14px",
  "fontFamily": "Roboto",
  "fontStyle": "normal",
  "fontWeight": "500",
  "fontSize": "12px",
  "lineHeight": "14px",
  "color": "#55595D"
}
const currentBidTimeStyle =
{
  "height": "14px",
  "fontFamily": "Roboto",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "12px",
  "lineHeight": "14px",
  "color": "#55595D"
}
const currentBidStyle = {
  "marginTop": "4px",
  "color": "black",
  "paddingTop": "8px",
  "paddingBottom": "8px",
  "fontFamily": "Roboto",
  "fontSize": "12px",
  "fontStyle": "normal",
  "fontWeight": "600",
  "lineHeight": "14px",
  "letterSpacing": "0em",
  "textAlign": "left",
  "overflow": "hidden",
  "background": "linear-gradient(106.65deg, #B1FF82 -6.26%, #3EF9AA 124.55%)",
  "borderRadius": "4px",
  "display": "flex",
  "justifyContent": "space-between"
}
const BidHistory = ({
  bidHistory,
  lastBid,
  address,
  iconName = "algo",
  unitname
}) => {
  const copy = useCopy()
  return <>
    {bidHistory.length > 0 && <>
      <Col style={bidLabelStyle} xs={12}>
        Order history</Col>
      <Col style={bidHistoryStyle} xs={12}>
        {bidHistory.slice(0,4).map(({ amount, sender, time }, i) => <div key={i} style={{
          "display": "flex",
          "justifyContent": "space-between", "marginTop": "10px"
        }}>
          <span onClick={() => copy(sender)} style={{ ...currentBidAddressStyle, cursor: "pointer" }} xs={6}>
            {address === sender
              ? ("(you) " + sender).substr(0, 12).concat('...')
              : formatCompactAddress(sender)}
          </span>
          <span xs={3} style={{
            ...bidAmountTypography,
            "display": "inline-flex",
            "columnGap": "5px",
          }}>
            {unitname  ? <span>{amount} {unitname}</span> :  <><Icon name={iconName} size={12} /><span>{amount}</span></>}
          </span>
          <span style={currentBidTimeStyle} xs={3}>{time}</span>
        </div>)}
      </Col>
    </>}
    {lastBid && <>
      <Col style={{ ...bidLabelStyle, ...bidLabelStyle2 }} xs={12}>
        Current winning bid</Col>
      <Col style={currentBidStyle} xs={12}>
        {(({ sender, amount, time }) => <>
          <span onClick={() => copy(sender)} style={{ ...currentBidAddressStyle, cursor: "pointer" }} xs={6}>{
            address === sender
              ? ("(you) " + sender).substr(0, 12).concat('...')
              : formatCompactAddress(sender)}
          </span>
          <span xs={3} style={{
            ...bidAmountTypography,
            "display": "inline-flex",
            "columnGap": "5px",
          }}>
            {unitname ? <span>{amount} {unitname}</span> : <><Icon name={iconName} size={12} /><span>{amount}</span></>}
          </span>
          <span style={currentBidTimeStyle} xs={4}>{time}</span>
        </>)(lastBid)}
      </Col>
    </>}
  </>
}
export default BidHistory;