import { useEffect, useRef } from 'react'
import {
  useHistory,
  useParams
} from "react-router-dom"
import * as backend from '../build/index.main.mjs'
import { useState } from "react";
import { Col, Image, Row } from "react-bootstrap"
import Spinner from 'react-bootstrap/Spinner'
import { DateTimePicker } from '@mui/lab'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import './Sell.css'
import auctionService from '../service/auctionService.js';
import reverseService from '../service/reverseService.js';
import tokenService from '../service/tokenService.js';

const axios = require('axios')

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let algoexplorerapi_endpoint
if (providerEnv === "MainNet") {
  algoexplorerapi_endpoint = 'https://algoexplorerapi.io'
} else {
  algoexplorerapi_endpoint = 'https://testnet.algoexplorerapi.io'
}

const getAsset = async (assetIndex) =>
  await axios.get(`${algoexplorerapi_endpoint}/v1/asset/${assetIndex}`)

const getUrlPath = (url) =>
  ((delimeter) =>
    url.split(delimeter).slice(2).join(delimeter))
    ('/')

const getCFIPFSUrl = (path) =>
  `https://cloudflare-ipfs.com/ipfs/${path}`

const getCFIPFS = async (path) =>
  await axios.get(getCFIPFSUrl(path))

const getAssetImage = async (assetIndex) => {
  let image
  try {
    const asset = await getAsset(assetIndex)
    const { url } = asset.data
    if (url.indexOf('https://') !== -1) {
      return url
    }
    else if (url.indexOf('ipfs') !== -1) {

      if (url.indexOf('json') !== -1) {
        // url is metadata json with relative path to image
        const maybeImage = (await getCFIPFS(getUrlPath(url))).data
        var path = require('path')
        image = [path.dirname(url), maybeImage.image].join('/')
      } else {
        if (url.indexOf('pinata') === -1) {
          // TODO url is another ipfs resource
          const maybeMetadata = (await getCFIPFS(getUrlPath(url))).data
          if (maybeMetadata.image.indexOf('ipfs') !== -1) {
            // use gateway url
            image = getCFIPFSUrl(getUrlPath(maybeMetadata.image))
          } else {
            // use url as is
            image = maybeMetadata.image
          }
        } else {
          // pinata url contains image
          image = url
        }
      }

    } else {
      // url may be gateway url
      image = url
    }
  } catch (e) {
    // use placeholder as fallback
    image = 'https://via.placeholder.com/1024'
  }
  return image
}

const getNodeStatus = async () => {
  let { status, data } = await axios.get(`${algoexplorerapi_endpoint}/v2/status`)
  if (status === 200) {
    return data
  }
  return null
}

const Sell = (props) => {
  var moment = require('moment-timezone');
  document.title = "Sell - NFT Jam"
  let history = useHistory()
  let { appId } = useParams()
  const sliderRef = useRef(null)
  let sliderTimeout
  const {
    acc,
    stdlib,
    ASSET_ID_JAM,
    ADDR_PLATFORM,
    ADDR_DISCOVERY,
    ADDR_DISCOVERY2,
    ADDR_PRIVATE,
  } = props
  const [loading, setLoading] = useState(false)
  const [sliderProps, setSliderProps] = useState({})
  const [networkTime, setNetworkTime] = useState(0)
  const [state, setState] = useState({
    success: false,
    loading: false,
    bank: null,
    buy: null,
    seconds: 0,
    step: 0
  })
  const [query, setQuery] = useState({
    ASSETID: 0,
    STARTBID: 1,
    RESERVEPRICE: 100,
    DEADLINEDATE: moment().add(1, 'day'),
    ENABLEDISCOVERY: true,
    ROYALTYCENTS: 10
  })
  const createAuction = (service, type) => {
    setLoading(true)
    service.create({ addr: acc.address })
    .then((res) => {
      console.log({ res })
      let { id } = res
      if (id > 0)
        history.push(`/sell-${type}/${id}`)
    })
    .catch(e => {
      console.dir(e)
      setLoading(false)
    })
  }
  const getRoyaltyParams = rc => ({
    royaltyAddr: rc > 0
      ? String(query.CREATOR)
      : ADDR_PLATFORM,
    royaltyCents: rc === 0
      ? 1
      : rc
  })
  const handleSubmit = async () => {
    // validate sell nft form
    if (query.ASSETID === 0) {
      alert("Must select asset")
      return
    }
    else if (query.STARTBID <= 0) {
      alert("Start bid must be greater than zero")
      return
    }
    else if (query.RESERVEPRICE <= query.STARTBID) {
      alert("Reserve price must be greater tha start price")
      return
    }
    else if (moment().unix() > query.DEADLINEDATE.unix()) {
      alert("Deadline date must be in the future")
      return
    }
    else if (parseInt(query.ROYALTYCENTS) < 0) {
      alert(`Royalty % must be greater than or equal to 0`)
      return
    }
    else if (parseInt(query.ROYALTYCENTS) >= 99) {
      alert("Royalty % must be less than 99")
      return
    }

    let ctcAuctionInfo
    const getParams = () => ({
      addr: ADDR_PLATFORM,
      addr2: query.ENABLEDISCOVERY
        ? ADDR_DISCOVERY : ADDR_PRIVATE,
      addr3: ADDR_DISCOVERY2,
      ...getRoyaltyParams(query.ROYALTYCENTS),
      discoveryEnabled: query.ENABLEDISCOVERY,
      tokB: ASSET_ID_JAM,
      token: parseInt(query.ASSETID),
      reservePrice: stdlib.parseCurrency(query.RESERVEPRICE), // 100 ALGO
      startPrice: stdlib.parseCurrency(query.STARTBID), // 0 ALGO
      bidIncrementAbs: stdlib.parseCurrency("1"), // 1 ALGO
      bidIncrementRel: 1, // 1% 
      deadline: 50, // 50 blocks
      deadlineSecs: query.DEADLINEDATE.unix(), // x secs 
      maxDeadline: 1000, // y blocks, where y > x
      unitAmount: stdlib.parseCurrency(1),
      deadlineStep: 10
    })
    const signal = async () => {
      let image = await getAssetImage(parseInt(query.ASSETID))
      console.log({ image })
      setState({
        ...state,
        //loading: true,
        success: true,
        appId: ctcAuctionInfo,
        image
      })
    }
    const closed = () => {
      console.log("CLOSED")
    }
    const commonInteract = {
      ...stdlib.hasConsoleLogger
    }
    const auctioneerInteract = {
      ...commonInteract,
      getParams,
      signal,
      closed
    }
    // DEBUG
    //console.log(stdlib.bigNumberToNumber(await stdlib.getNetworkSecs()))
    //console.log(getParams())
    if (!appId) {

      setState({ ...state, loading: true })
      console.log("Launching new auction ...")
      // TODO depreciated
      //const ctcAuction = acc.deploy(backend)
      const ctcAuction = acc.contract(backend)
      Promise.all([
        //backend.Constructor(ctcAuction, constructorInteract),
        backend.Auctioneer(ctcAuction, {
          ...stdlib.hasConsoleLogger,
          getParams: () => ({
            addr: ADDR_PLATFORM,
            addr2: query.ENABLEDISCOVERY
              ? ADDR_DISCOVERY : ADDR_PRIVATE,
            ...getRoyaltyParams(query.ROYALTYCENTS),
            addr3: ADDR_DISCOVERY2,
            discoveryEnabled: query.ENABLEDISCOVERY,
            royaltyAddr: (rc => rc > 0 ? String(query.CREATOR) : query.ALLOWDISCOVERY ? ADDR_PRIVATE : ADDR_PLATFORM)(query.ROYALTYCENTS),
            royaltyCents: (rc => rc === 0 ? 1 : rc)(query.ROYALTYCENTS),
            tokB: ASSET_ID_JAM,
            token: parseInt(query.ASSETID || 24270812),
            reservePrice: stdlib.parseCurrency(query.RESERVEPRICE), // 100 ALGO
            startPrice: stdlib.parseCurrency(query.STARTBID), // 0 ALGO
            bidIncrementAbs: stdlib.parseCurrency("1"), // 1 ALGO
            bidIncrementRel: 1, // 1% 
            deadline: 50, // 50 blocks
            deadlineSecs: query.DEADLINEDATE.unix(), // x secs 
            maxDeadline: 1000, // y blocks, where y > x
            unitAmount: stdlib.parseCurrency(1),
            deadlineStep: 10
          }),
          signal: async () => {
            let image = await getAssetImage(parseInt(query.ASSETID))
            console.log({ image })
            setState({
              ...state,
              //loading: true,
              success: true,
              appId: ctcAuctionInfo,
              image
            })
          },
          closed: () => {
            console.log("CLOSED")
          }
        })
      ])
      ctcAuctionInfo = await ctcAuction.getInfo()
      appId = ctcAuctionInfo
      //history.push(`/sell/${appId}`)
      //setState({ ...state, loading: false })
    } else {

      if (!query.ASSETID
        || !query.STARTBID
        || !query.RESERVEPRICE
        || !query.DEADLINEDATE) {
        alert("Enter Asset id, Start bid, and Deadline date to submit")
        return
      }
      setState({ ...state, loading: true })
      ctcAuctionInfo = parseInt(appId)
      console.log("Launching auction ...")
      // TODO depreciated
      //let ctcAuction = acc.attach(backend, ctcAuctionInfo)
      let ctcAuction = acc.contract(backend, ctcAuctionInfo)
      Promise.all([
        backend.Auctioneer(ctcAuction, auctioneerInteract)
      ])
      appId = ctcAuctionInfo

    }
  }
  const initialState = {
    step: appId ? 1 : 0,
    loading: false
  }
  useEffect(() => {
    if (state.success) return
    let interval
    (async () => {
      interval = setInterval(async () => {
        //setNetworkTime(stdlib.bigNumberToNumber(await stdlib.getNetworkTime()))
        let status = await getNodeStatus()
        if (status) {
          setNetworkTime(status['last-round'])
        }
      }, 1000 * 5)
    })()
    return () => clearInterval(interval)
  }, [state.success])
  /*
  const somethingFromSome = (f, d) => (some) => some[1] ? f(some[1]) : d
  const bigNumberToNumberFromSome = (some) => somethingFromSome((some) => stdlib.bigNumberToNumber(some), 0)(some)
  */
  const SubmitButton = (props) => {
    const {
      loading,
      label,
      onClick
    } = props
    return loading
      ? (
        <div style={{
          ...buttonStyle,
          ...buttonTypographyStyle,
          ...{
            "marginTop": "40px",
            "opacity": ".9"
          }
        }}
          disabled><Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            style={{
              "height": "12px",
              "width": "12px"
            }}
          />Loading...</div>
      )
      : (
        <div role="button" style={{ ...buttonStyle, ...buttonTypographyStyle, ...{ "marginTop": "40px" } }} onClick={() => { onClick() }}>
          {label}
        </div>
      )
  }
  const handleChange = ({ target }) => {
    let { name, value } = target
    console.log({ name, value })
    switch (name) {
      case 'ASSETID':
        let CREATOR = acc.assets.filter(el => el['asset-id'] === value)[0].creator
        if (CREATOR === acc.address) {
          setQuery({
            ...query,
            [name]: value,
            CREATOR,
            ROYALTYCENTS: 0,
          })
        } else {
          setQuery({
            ...query,
            [name]: value,
            CREATOR
          })
        }
        break
      case 'STARTBID':
      case 'RESERVEPRICE':
      case 'ROYALTYCENTS':
        if (value.length > 1 && value[0] === '0') {
          value = value.slice(1)
        }
        setQuery({
          ...query,
          [name]: value
        })
        break
      default:
    }
  }
  const handleSellOneMore = () => {
    appId = null
    history.push(`/sell`)
    setState({
      success: false
    })
    setQuery({})
  }
  const serviceNameTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "900",
    "fontSize": "32px",
    "lineHeight": "38px",
    "textAlign": "center",
    "letterSpacing": "0.1em",
    "textTransform": "uppercase"
  }
  const cardStyle =
  {
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "padding": "50px 56px",
    "width": "565px",
    //"height": "645px",
    "paddingBottom": "50px"
  }
  const compactCardStyle =
  {
    "position": "fixed",
    "top": "15%",
    "left": "5vw",
    "width": "90vw",
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "padding": "20px 30px",
    "marginTop": "0vh",
    //"maxWidth": "90vw",
    //"maxHeight": "70vh",
    //"width": "465px",
    //"height": "588px"
  }

  const labelTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "300",
    "fontSize": "12px",
    "lineHeight": "14px",
    "color": "#C558F3"
  }
  const labelStyle =
  {
    "height": "14px",
    "marginBottom": "4px"
  }
  const buttonStyle =
  {
    "height": "50px",
    "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
    "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
    "borderRadius": "44px",
    "marginTop": "46px"
  }
  const buttonTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "14px",
    "lineHeight": "50px",
    "textAlign": "center",
    "textTransform": "uppercase",
    "color": "#FFFFFF"
  }
  const cancelStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "14px",
    "lineHeight": "17px",
    "textAlign": "center",
    "textTransform": "uppercase",
    "color": "#FF4747",
    "marginTop": "32px"
  }
  return <div id="sell" style={{ paddingBottom: "100px" }}>
      <div id="sell" className="" style={cardStyle}>
        <div style={serviceNameTypographyStyle}>Sell Your NFT</div>
        <Stack className="mt-4 pt-1" spacing={5}>
          <Box style={{ "textAlign": "left" }}>
            <p>
              Choose the auction to sell your nft.
            </p>
            <p>
              <strong>Auction</strong> starts at some lower price increasing with each bid.
            </p>
            <p>
              <strong>Token auction</strong> starts at some lower price increasing with each bid accepting payment in any token.
            </p>
            <p>
              <strong>Reverse auction</strong> start at some higher price decreasing as time runs out.
            </p>
            <p>
              Read more in docs regarding <a href="#">auction types</a>.
            </p>
            {!loading ? <>
            <SubmitButton as={Box} onClick={() => createAuction(auctionService, 'auction')} label="Auction" />
            <SubmitButton as={Box} onClick={() => createAuction(tokenService, 'token')} label="Token auction" />
            <SubmitButton as={Box} onClick={() => createAuction(reverseService, 'reverse')} label="Reverse auction" />
            </> : "Loading..."}
          </Box>
        </Stack>
      </div>
  </div >
}

export default Sell;